@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300..600&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined&display=block");


pre {
  overflow: auto;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

a, button {
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 100ms;
}


#enpsearch {
  font-size: 16px;
  font-family: "Inter", sans-serif;
  color: var(--color-text);
  background-color: var(--color-background2);
}

#enpsearch {
    --color-black: #000000;
    --color-blue: #0562F7;
    --color-brandDark: #003040;
    --color-brandPrimary: #2FCACA;
    --color-green: #23C248;
    --color-red: #FA0000;
    --color-white: #FFFFFF;
    --color-yellow: #F5B905;

    --color-background1: #F5F5F5;
    --color-background1Blue: #E1EAFA;
    --color-background1BrandPrimary: #D1F0F0;
    --color-background1Green: #CEEED6;
    --color-background1Hover: #F5F5F5;
    --color-background1Red: #F9E0E1;
    --color-background1Web: #FAFAFA;
    --color-background1Yellow: #F8ECC9;
    --color-background2: #FFFFFF;
    --color-background2Blue: #E5EFFD;
    --color-background2BrandPrimary: #D6F5F4;
    --color-background2Green: #D0F5D8;
    --color-background2Red: #FFE5E4;
    --color-background2Yellow: #FFF0CC;
    --color-background3: #F5F5F5;
    --color-backgroundObject: #E5E5E7;
    --color-backgroundObjectActive: #D7D7D9;
    --color-backgroundObjectHover: #DCDCDE;
    --color-blueActive: #055AE3;
    --color-blueCF: #0562F7;
    --color-blueHover: #196FF8;
    --color-border: #D6D6D8;
    --color-borderFocus: #0562F7;
    --color-borderHover: #C2C2C4;
    --color-brandPrimaryCF: #12B8C2;
    --color-divider1: #DBDBDD;
    --color-divider2: #EBEBED;
    --color-gray1: #9E9EA0;
    --color-gray2: #BDBDBF;
    --color-greenCF: #20B644;
    --color-redCF: #FA0000;
    --color-text: #000000;
    --color-textGray: #7A7A7C;
    --color-textGray2: #2E2E30;
    --color-textGrayActive: #000000;
    --color-textGrayHover: #0F0F0F;
    --color-textInputHint: #A3A3A5;
    --color-textLink: #0562F7;
    --color-textLink2: #044EC6;
    --color-textLink2Active: #0562F7;
    --color-textLink2Hover: #0562F7;
    --color-textLinkActive: #044EC6;
    --color-textLinkHover: #044EC6;
    --color-yellowCF: #F09E05;

  --color-border: rgb(231, 231, 231);
  --color-textGray: #56565d;

  --color-background1Hover: rgb(235, 235, 235);
  --color-background3: #f9f9f9;
}

#enpsearch[data-theme="dark"] {
    --color-background1: #050505;
    --color-background1Blue: #021E50;
    --color-background1BrandPrimary: #0F4141;
    --color-background1Green: #0B3E17;
    --color-background1Hover: #0F0F0F;
    --color-background1Red: #500000;
    --color-background1Web: #050505;
    --color-background1Yellow: #4E3B02;
    --color-background2: #19191A;
    --color-background2Blue: #132F5C;
    --color-background2BrandPrimary: #1F4748;
    --color-background2Green: #1C4526;
    --color-background2Red: #4D1414;
    --color-background2Yellow: #524315;
    --color-background3: #29292B;
    --color-backgroundObject: #38383A;
    --color-backgroundObjectActive: #343435;
    --color-backgroundObjectHover: #48484A;
    --color-blueActive: #055AE3;
    --color-blueCF: #1F72FF;
    --color-blueHover: #196FF8;
    --color-border: #333335;
    --color-borderFocus: #1F72FF;
    --color-borderHover: #474749;
    --color-brandPrimaryCF: #2FCACA;
    --color-divider1: #242426;
    --color-divider2: #333335;
    --color-gray1: #757577;
    --color-gray2: #525254;
    --color-greenCF: #2BD852;
    --color-redCF: #FF1515;
    --color-text: #FFFFFF;
    --color-textGray: #B8B8BA;
    --color-textGray2: #D1D1D3;
    --color-textGrayActive: #FFFFFF;
    --color-textGrayHover: #F0F0F2;
    --color-textInputHint: #ADADAF;
    --color-textLink: #FFFFFF;
    --color-textLink2: #AACDF5;
    --color-textLink2Active: #6EA8FC;
    --color-textLink2Hover: #6EA8FC;
    --color-textLinkActive: #6EA8FC;
    --color-textLinkHover: #6EA8FC;
    --color-yellowCF: #FAC01B;

  --color-border: var(--color-divider2);
  --color-textGray: #c2c2c2;
  --color-backgroundObject: #19191b;
  --color-background3: #29292b;

  --color-background1Hover: rgb(33, 33, 34);
}
