#enpsearch {
    --enp-text: var(--color-text);
    --enp-text-gray: var(--color-textGray);
    --enp-text-gray-hover-color: var(--color-textLink2Hover);
    --enp-border-color: var(--color-border);

    --enp-container-width: 832px;
    --enp-backdrop-background: rgb(0, 0, 0, 0.7);

    --enp-header-background: var(--color-background1);
    --enp-header-padding-x: 16px;

    --enp-result-container-background: var(--color-background1);
    --enp-result-container-padding-x: 16px;

    --enp-result-item-background: var(--color-background2);
    --enp-result-item-border: var(--enp-border-color);
    --enp-result-item-padding-x: 16px;
    --enp-result-item-padding-y: 12px;
    --enp-result-item-subtitle-background: transparent;
    --enp-result-item-subtitle-color: var(--enp-text-gray);
    --enp-result-item-title-color: var(--enp-text);
    --enp-result-item-title-hover-color: var(--color-textLink2Hover);

    --enp-search-input-background: var(--color-background2);
    --enp-search-input-border-color: var(--enp-border-color);
    --enp-search-input-placeholder: var(--color-gray1);
    --enp-search-input-ring-color: var(--color-textLink2Hover);

    --enp-subgroup-result-item-background: var(--color-background3);
    --enp-subgroup-result-item-border-color: var(--enp-border-color);
    --enp-subgroup-result-item-border-hover-color: var(--color-textLink2Hover);

    --enp-tag-background: transparent;
    --enp-tag-border: var(--enp-border-color);

    --enp-tooltip-background: var(--color-background3);

    --enp-item-button-background: var(--color-blue);
    --enp-item-button-text-color: var(--color-white);
}
