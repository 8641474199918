/* Tooltip container */
.tooltip {
  position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 90px;
  background-color: var(--enp-tooltip-background);
  color: var(--enp-text-gray);
  text-align: center;
  padding: 5px 4px;
  border-radius: 6px;
  border: 1px solid var(--enp-border-color);
  font-size: 11px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

@media (max-width: 767px) {
  .tooltiptext {
    display: none;
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext-b {
  width: 90px;
  top: 105%;
  left: 50%;
  margin-left: -45px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

.tooltip .tooltiptext-lb {
  width: 90px;
  top: 105%;
  right: 100%;
  margin-right: -50px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

.tooltip .tooltiptext-t {
  width: 90px;
  bottom: 105%;
  left: 50%;
  margin-left: -45px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

.tooltip .tooltiptext-lt {
    width: 90px;
    bottom: 105%;
    right: 50%;
    margin-right: -45px; /* Use half of the width (120/2 = 60), to center the tooltip */
  }

.tooltip .tooltiptext-l {
  top: -5px;
  right: 105%;
}

.tooltip .tooltiptext-r {
  top: -5px;
  left: 105%;
}
