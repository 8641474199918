/* @import "./scroll.css"; */

hr {
  border-top-color: var(--color-divider2);
}

b, strong {
  font-weight: 500;
}

.markdown {

}

.markdown pre {
  word-wrap: normal;
  word-break: normal;
}

.markdown ol,
.markdown ul {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.markdown li ol,
.markdown li ul {
  margin-left: 12px;
  gap: 4px;
}

.markdown ol li {
  list-style: decimal;
}

.markdown ul li {
  list-style: disc;

}
